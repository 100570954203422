import React from "react"

function InfoIcons() {
    return(
        <section className="icons-container">
            <div className="icons">
                <i className="fas fa-paper-plane"></i>
                <div className="content">
                    <h3>Livraison</h3>
                    <p>Rapide</p>
                </div>
            </div>
            <div className="icons">
                <i className="fas fa-redo"></i>
                <div className="content">
                    <h3>Retours</h3>
                    <p>faciles</p>
                </div>
            </div>
            <div className="icons">
                <i className="fas fa-lock"></i>
                <div className="content">
                    <h3>Paiement</h3>
                    <p>à la livraison</p>
                </div>
            </div>
            <div className="icons">
                <i className="fas fa-headset"></i>
                <div className="content">
                    <h3>Support </h3>
                    <p>Téléphonique</p>
                </div>
            </div>
        </section>
        )
}

export default InfoIcons