import React from "react"

export default function ContactSupport() {
    return (
        <section id="newsletter">
            <form action="">
                <h3>InfoLine pour plus d'informations !</h3>
                <input type="submit" value="TEL: 22 330 605" className="btn" />
            </form>
        </section>
    )
}