import React from "react"


function Fomolaire() {
    return (
        
        <section id="buyneeeeeow">
        <h2 className="heading space-louta"><span>Acheter !</span></h2>
            <iframe 
                src={"https://tally.so/r/nGxKRz?transparentBackground=1"}
                title="Form"
                frameBorder="0" 
                width={"100%"} 
                height={"1950px"} 
                style={{background: "transparent"}} 
            />
            <div style={
                {
                    position: "relative",
                    background: "#fff",
                    height: "100px",
                    marginTop: "-100px"}
            } />
        </section>
        
    )
}

export default Fomolaire