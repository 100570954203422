import React, {useState, useCallback} from "react"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import { photos } from "./childComponents/photos"
 

function RealPhotos() {

    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }, [])

    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    }

    return (
        <section id="photos-reels">
        <h2 className="heading"><span>Extrait du contenu</span></h2>
        <div className="featured-swiper swiper">
            <br />  <br /> <br />
            <div className="">
                {/* <SingleRealPhoto />
                <SingleRealPhoto />
                <SingleRealPhoto />
                <SingleRealPhoto /> */}
                <Gallery photos={photos} onClick={openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                        currentIndex={currentImage}
                        views={photos.map(x => ({
                            ...x,
                            srcset: x.srcSet,
                            caption: x.title
                        }))}
                        />
                    </Modal>
                    ) : null}
                </ModalGateway>
            </div>
            

        </div>
    </section>
    )
}

export default RealPhotos