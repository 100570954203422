import React from "react"

function StrongPoints() {
    return (
        <section id="strong-points">
        <h2 className="heading space-louta"><span>Le Contenu</span></h2>
                <div className="strong-points-p">
                    <div>
                        <center>
                            <h2 className="chtat">Dans Notre Tome I,<u> 205</u> Pages Ouvrage </h2>
                            <h1>Nous vous proposons :</h1>
                        </center>
                        <ul style={{"fontSize": "17px"}}>
                                <li className="loulou">• 5 Fiches d’aide de qualité dont la dernière aucun professeur ne peut vous la divulguer.</li> 
                                <li className="loulou">• Le résumé des 3 Thèmes (Reproduction Humaine et Santé, Génétique et L'évolution Biologique) simplifiés par des schémas et détaillés pour simplifier leur apprentissage.</li>
                                <li className="loulou">• Des Séries avec des Exercices applicables au BAC bien choisis avec des corrigés.</li> 
                                <li className="loulou">• Des devoirs et QCM avec corrigés.</li>
                        </ul>
                    </div>
                    
                    <div>
                        <center>
                        <span className="phone-line">__________________________________</span><br className="phone-line"/><br className="phone-line"/><br className="phone-line"/>
                            <h2 className="chtat">Dans Notre Tome II,<u> 229</u> Pages Ouvrage </h2>
                            <h1>Nous vous proposons :</h1>
                        </center>
                        <ul style={{"fontSize": "17px"}}>
                            <li className="loulou">• 5 Fiches d’aide de qualité dont la dernière aucun professeur ne peut vous la divulguer.</li> 
                            <li className="loulou">• Le résumé des 2 Thèmes (NEUOPHYSIOLOGIE et IMMUNITE) simplifiés par des schémas et détaillés pour simplifier leur apprentissage.</li>
                            <li className="loulou">• Des Séries avec des Exercices applicables au BAC bien choisis avec des corrigés.</li> 
                            <li className="loulou">• Des devoirs et QCM avec corrigés.</li>
                        </ul>
                    </div>
                </div>
                <br /><br />

                
                <div class="video-container">
                    <iframe src="https://www.youtube.com/embed/R08rsL735gc" title="Livre SVT" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    
                </div>
                <center>
                    <br />
                <a href="#buyneeeeeow" className="btn" style={{background:"#e34d4c"}}>Acheter | شراء الكتاب</a>
                </center>
                
        </section>
    )
}

export default StrongPoints
