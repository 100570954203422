export const photos = [
    {
      src: "./images/previewOne-min.png",
      width: 3,
      height: 4.3
    },
    {
      src: "./images/previewTwo-min.png",
      width: 3,
      height: 4.3
    },
    {
      src: "./images/previewThree-min.png",
      width: 3,
      height: 4.3
    },
    {
      src: "./images/previewFour-min.png",
      width: 3,
      height: 4.3
    },
    
  ];
  