import React from "react"

function Header() {
    return (
        <header className="header">
            {  /* <div id="header-1">
              
                <a href="#" id="logo"><i className="fas fa-book"></i>SVT.TN</a>

                <div className="icons">
                    <div id="search-btn" className="fas fa-search"></div>
                    <a href="#" className="fas fa-heart"></a>
                    <a href="#" className="fas fa-shopping-cart"></a>
                    <div id="login-btn" className="fas fa-user"></div>
                </div> 
                
            </div> */}
            <div id="header-2">
                <nav className="navbar">
                    <div>
                        <a href="#buyneeeeeow" id="logo"><i className="fas fa-book"></i> SVT.TN</a>
                    </div>
                    <div>
                        <a href="#home">accueil</a>
                        <a href="#photos-reels">Photos Réels</a>
                        <a href="#buyneeeeeow">Acheter !</a>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header