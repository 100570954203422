import React from "react"
import Header from "./components/Header"
import Hero from "./components/Hero"
import InfoIcons from "./components/InfoIcons"
import RealPhotos from "./components/RealPhotos"
import ContactSupport from "./components/ContactSupport"
import Fomolaire from "./components/Fomolaire"
import StrongPoints from "./components/StrongPoints"

function App() {
    return (
        <>
            <Header />
            <Hero />
            <InfoIcons />
            <StrongPoints />
            <RealPhotos />
            <ContactSupport />
            <Fomolaire />
        </>
    )
}

export default App