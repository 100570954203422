import React from "react"

function Hero() {
    return (
    <section id="home">
        <div className="row">
            <div className="content">
                <h3>Comment décrocher une <span style={{textDecoration: "underline"}}>excellente</span> note en SVT au BAC Sciences Expérimentales?</h3>
                <p>
                    {`Êtes-vous au BAC Sciences Expérimentales? Vous vous sentez un peu perdu pendant cette période de révision?
                    Vous cherchez de l'aide et un support fabuleux afin d'entamer ces moments forts d'avant épreuve, sans être stressé(e)?
                    Cet ouvrage est destiné spécifiquement pour vous!`}
                </p>

                
                <a href="#buyneeeeeow" className="btn" style={{background:"#e34d4c"}}>Acheter | شراء الكتاب</a>
            </div>
            <div className="swiper books-slider">
                <a href="#buyneeeeeow" className="swiper-slide"><img src="images/lvavBlue.png" alt="book" /></a>        
                <img src="images/stand.png" alt="stand" id="stand" className="raf-phone" />

                <a href="#buyneeeeeow" className="swiper-slide"><img src="images/lvav.png" alt="book" /></a>
                <img src="images/stand.png" alt="stand" id="stand" />
            </div>
        </div>
    </section>
    )
}

export default Hero